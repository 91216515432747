<template>
  <div class="card-contatos">
    <h3 class="text-2xl text-regular-blue font-bold">Contatos</h3>
    <div class="grid grid-cols-1 gap-4 content-start md:grid-cols-2">
      <div
        class="px-4 pb-4 bg-regular-blue border-solid border border-gray rounded-lg flex flex-col justify-center items-center text-center"
      >
        <div
          class="text-2xl text-white flex flex-row items-center justify-start w-full"
        >
          <img alt="" class="w-9 m-3" :src="icone.ouvidoria" />
          Ouvidoria Geral
        </div>
        <span class="text-base text-white text-left">
          Endereço: Rua João Leite Ribeiro, 754 (prédio da Prefeitura) <br />
          Horário de Funcionamento: de segunda à sexta-feira, das 7h às 11h e
          das 13h às 17h <br />
          Telefone: 67 3245-3540 <br />
          E-mail: ouvidoria@anastacio.ms.gov.br <br />
          <a class="link" href="/assets/86FC789C.pdf" target="_black"
            >Regulamentação da Lei de Acesso à Informação</a
          >
        </span>
      </div>
      <div
        class="px-4 pb-4 bg-regular-blue border-solid border border-gray rounded-lg flex flex-col justify-center items-center text-center"
      >
        <div
          class="text-2xl text-white flex flex-row items-center justify-start w-full"
        >
          <img alt="" class="w-9 m-3" :src="icone.ouvidoria_sus" />
          Ouvidoria SUS
        </div>
        <span class="text-base text-white text-left">
          Endereço: Av. Manoel Murtinho, 1879, mesmo prédio Procon. <br />
          Horário de Funcionamento: de segunda à sexta-feira, das 7h às 11h e
          das 13h às 17h <br />
          Telefone: 67 3245-1741 |
          <a
            class="link"
            href="https://api.whatsapp.com/send/?phone=5567992485516&text&type=phone_number&app_absent=0"
            target="_black"
            >67 99248-5516</a
          >
          (whats) <br />
          E-mail: ouvidoriaanastacioms@gmail.com <br />
          WhatsApp:
          <a
            class="link"
            href="https://api.whatsapp.com/send/?phone=5567984343997&text&type=phone_number&app_absent=0"
            target="_black"
            >67 98434-3997</a
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icone: {
        ouvidoria: require("@/assets/icons/ouvidoria.svg"),
        ouvidoria_sus: require("@/assets/icons/ouvidoria_sus.svg"),
      },
    };
  },
};
</script>

<style lang="scss">
.card-contatos {
  max-width: 1440px;
  margin: 2rem auto;
  padding: 0 2rem;
}

.link:hover {
  color: #fff;
  text-decoration: underline;
}
</style>
