import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
//import NewsView from "../views/NewsView.vue";
import AllNewsView from "../views/AllNewsView.vue";
import OfficesView from "../views/OfficesView.vue";
import OfficesViewId from "../views/OfficesViewId.vue";
import GalleryView from "../views/GalleryView.vue";
//import PhotosView from "../views/PhotosView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/noticias/:pg",
    name: "noticias",
    component: AllNewsView,
  },
  {
    path: "/noticia/:id/:title",
    name: "noticia",
    component: HomeView,
  },
  {
    path: "/galerias/:pg",
    name: "galerias",
    component: GalleryView,
  },
  {
    path: "/galeria/:id/:title",
    name: "photos",
    component: HomeView,
  },
  {
    path: "/secretarias/:id/:title",
    name: "secretariasWithTitle",
    component: OfficesView,
  },
  {
    path: "/secretarias/:id/",
    name: "secretariasWithId",
    component: OfficesView,
  },
  {
    path: "/secretarias/:id/:title/:type/:id_page/:title_page",
    name: "secretariasWithIdPage",
    component: OfficesViewId,
  },
  {
    path: "/secretarias",
    name: "secretarias",
    component: OfficesView,
  },
  {
    path: "/prefeitura/:id/:title",
    name: "prefeitura",
    component: () => import("../views/Prefecture.vue"),
  },
  {
    path: "/links",
    name: "links",
    component: () => import("../views/LinksView.vue"),
  },
  {
    path: "/contato",
    name: "contato",
    component: () => import("../views/ContatoView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
