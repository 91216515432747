<template>
  <div class="navbar-holder">
    <loading
      :active="loading"
      :can-cancel="false"
      :is-full-page="false"
    ></loading>
    <div class="navbar-top" v-if="!loading">
      <vue-navigation-bar
        :options="navbarOptions"
        @vnb-item-clicked="vnbItemClicked"
      />
    </div>
  </div>
</template>

<script>
import VueNavigationBar from "./VueNavigationBar/vue-navigation-bar.vue";
import Loading from "vue3-loading-overlay";
import hamburger from "../assets/icons/hamburger.svg";
import myMixin from "../mixins";

export default {
  components: {
    "vue-navigation-bar": VueNavigationBar,
    Loading,
  },
  mixins: [myMixin],
  data() {
    return {
      loading: false,
      secretariasSubMenuOptions: [],
      navbarOptions: {},
    };
  },
  methods: {
    async getSecretarias() {
      this.loading = true;
      const res = await this.$axios
        .get(`v1/secretarias`, {
          params: {
            prefeitura: 1,
            ativo: 1,
          },
        })
        .catch((e) => {
          console.error("Notícias", e);
        });
      if (res.status === 200) {
        this.secretariasSubMenuOptions = res.data.map((item) => ({
          isLinkAction: true,
          type: "link",
          text: item.titulo,
          path: `/secretarias/${item.id}/${this.slugify(item.titulo)}`,
        }));
        this.initializeNavbarOptions();
      }
      this.loading = false;
    },

    vnbItemClicked() {},

    initializeNavbarOptions() {
      this.navbarOptions = {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        collapseButtonImageOpen: hamburger,
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        tooltipPlacement: "bottom",
        menuOptionsLeft: [
          {
            isLinkAction: true,
            type: "link",
            text: "Principal",
            arrowColor: "#659CC8",
            subMenuOptions: [
              /* {
                isLinkAction: true,
                type: "link",
                text: "Galeria de Imagens",
                path: "/galerias/1",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Notícias",
                path: "/noticias/1",
                iconLeft: "",
              }, */
              {
                isLinkAction: true,
                type: "link",
                text: "Links",
                path: "/links",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Contato",
                path: "/contato",
                iconLeft: "",
              },
            ],
          },
          {
            type: "link",
            text: "Prefeitura",
            arrowColor: "#659CC8",
            subMenuOptions: [
              {
                isLinkAction: true,
                type: "link",
                text: "Prefeito",
                path: "/secretarias/11/prefeitura",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Conheça nossa cidade",
                path: "/prefeitura/2/conheca-nossa-cidade",
                arrowColor: "#659CC8",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Nossa História",
                path: "/prefeitura/3/nossa-historia",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Simbolos",
                path: "/prefeitura/4/simbolos",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Censo",
                path: "/prefeitura/5/censo",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Ex-prefeitos",
                path: "/prefeitura/6/prefeitos",
                iconLeft: "",
              },
            ],
          },
          {
            isLinkAction: false,
            type: "link",
            text: "Secretarias",
            subMenuOptions: this.secretariasSubMenuOptions,
            arrowColor: "#659CC8",
          },
          {
            isLinkAction: true,
            type: "link",
            text: "Serviços",
            subMenuOptions: [
              {
                isLinkAction: true,
                type: "link",
                text: "Escolas Municipais da Zona Urbana",
                path: "/secretarias/5/educacao/util/9/escolas-municipais-da-zona-urbana",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Escolas Municipais da Zona Rural",
                path: "/secretarias/5/educacao/util/10/escolas-municipais-da-zona-rural",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Cent de Educação Infantil",
                path: "/secretarias/5/educacao/util/13/centros-de-educacao-infantil",
                iconLeft: "",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Atenção Primária em Saúde (APS)",
                path: "/secretarias/7/saude/pagina/44/atencao-primaria-em-saude-aps",
                iconLeft: "",
              },
            ],
            arrowColor: "#659CC8",
          },
          {
            isUsingLink: true,
            isLinkAction: false,
            type: "link",
            text: "Diário",
            path: "https://transparencia.anastacio.ms.gov.br/diario",
            arrowColor: "#659CC8",
          },
          {
            isUsingLink: true,
            isLinkAction: false,
            type: "link",
            text: "Transparência",
            path: "https://transparencia.anastacio.ms.gov.br/",
            arrowColor: "#659CC8",
          },
          {
            isLinkAction: true,
            type: "link",
            text: "Contato",
            path: "/contato",
            class: "button-red",
          },
        ],
        menuOptionsRight: [],
      };
    },
  },
  mounted() {
    this.getSecretarias();
  },
};
</script>

<style lang="scss">
@import "./VueNavigationBar/assets/css/vue-navigation-bar.css";
.navbar-holder {
  width: 100%;
  background-color: #0a65ab;
}
.navbar-top {
  width: 100%;
  background-color: #0a65ab;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.vnb {
  background-color: #0a65ab !important;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 !important;
  &__brand-image-wrapper {
    &__link {
      &__image {
      }
    }
  }

  &__menu-options {
    &--left {
    }

    &--right {
    }

    &__option {
      &__link {
        color: #ffffff !important;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        margin: 12px;
        padding: 0 20px 0 0;
        border-right: rgba(255, 255, 255, 0.6) solid 1px;

        .not-bordered {
          border: none;
        }

        @media (max-width: 1210px) {
          padding: 0 10px 0 0;
          font-size: 14px;
          line-height: 16px;
        }

        &:hover {
        }

        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }
      }

      &__arrow {
        &--hover {
        }
      }

      &__button {
        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }
      }

      &__spacer {
      }

      &:last-child .vnb__menu-options__option__link {
        border: none;
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:hover {
        }

        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }

        &__text-wrapper {
          &__text {
          }

          &__sub-text {
          }
        }
      }

      &__hr {
      }
    }
  }

  &__collapse-button {
    padding: 10px;
    &:hover {
    }

    &__image {
      color: #ffffff;
      filter: brightness(0) invert(1);
      padding: 2px;
    }
  }

  &__popup {
    &__top {
      &__image {
      }

      &__close-button {
        &:hover {
        }

        &__image {
        }
      }
    }

    &__bottom {
      padding: 0;
      &__custom-section {
        padding: 0;
      }

      &__menu-options {
        margin-top: 0;
        &__option {
          &:not(:last-child) {
          }

          &__link {
            &:hover {
            }

            &--no-highlight {
              &:hover {
              }
            }

            &__icon {
              svg {
              }

              &--left {
                color: green;
                background-color: red;
              }

              &--right {
              }
            }
          }
        }
      }

      &__sub-menu-options {
        &__option {
          &__link {
            &:hover {
            }

            &__sub-text {
            }
          }
        }
      }
    }
  }
}

.vnb-button {
  &:hover {
  }
}
</style>
