<template>
  <!-- <HomeDestaque /> -->
  <NoticiasDestaque />
  <CardContatos />
</template>

<script>
//import HomeDestaque from "@/components/Home/HomeDestaque.vue";
import NoticiasDestaque from "../components/Home/NoticiasDestaque.vue";
import CardContatos from "../components/Home/CardContatos.vue";

export default {
  name: "HomeView",
  components: {
    //HomeDestaque,
    NoticiasDestaque,
    CardContatos,
  },
};
</script>

<style lang="scss">
.home {
  max-width: 1440px;
  margin: 2rem auto;

  @media screen and (max-width: 1440px) {
    margin: 2rem 1rem;
  }
}
</style>
